import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import LayoutAdmin from '../layout/Layout-admin';

class PrivateRoute extends React.Component {

    render() {
        const { RouteComp, authenticated, path, layout, ...rest } = this.props;
        console.log("authenticated", authenticated)
        return (
            <Route {...rest}
                render={props =>
                    typeof authenticated === undefined || authenticated === false
                        ? <Redirect to={{
                            pathname: '/login',
                            state: { from: path }
                        }} />
                        :
                        <LayoutAdmin> <RouteComp {...props} /></LayoutAdmin>

                }
            />
        )
    }
}

export default (PrivateRoute)