import React from 'react';



export function preloader() {
    return <i className="fa fa-spinner" aria-hidden="true"></i>;
}

export function bind(context, functions) {
    functions.forEach((f) => {
        context[f] = context[f].bind(context);
    });
}

// ajax loading circle
export function renderLoadingForm() {
    return (
        <div id="loader" className="hide ajax-loading">
            <div className="inner">
                <div className="preloader-wrapper big active">
                    <div className="spinner-layer spinner-orange-only">
                        <div className="circle-clipper left">
                            <div className="circle"></div>
                        </div>
                        <div className="gap-patch">
                            <div className="circle"></div>
                        </div>
                        <div className="circle-clipper pull-right">
                            <div className="circle"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export function iconLoading(showLoading) {
    // let $el = $('.loadingIcon');
    // if (!showLoading) {
    //     $el.addClass('loadingIcon');
    // } else {
    //     $el.addClass('loadingIcon').addClass('hide');
    // }
}

export function formLoading(showLoading) {
    let el = document.getElementById('loader');
    if (showLoading) {
        el.className = 'ajax-loading';
    } else {
        el.className = 'd-none ajax-loading';
    }
}

export const imgTypes = ["image/png", "image/gif", "image/jpeg", "image/jpg", "image/bmp"];

export const documentTypes = [
    'doc', 'xls', 'ppt', 'docx', 'xlsx', 'pptx', 'pdf', 'zip', 'xml', 'jpg', 'jpeg', 'jpe', 'tiff', 'png', 'gif'
];

export function validateImage(img) {
    if (img == null || img == undefined || img == "") {
        return {
            status: false,
            msg: "Please upload an image."
        }
    }

    let type = img.type;
    if (imgTypes.indexOf(type) <= -1) {
        return {
            status: false,
            msg: "Invalid image file type. file type not in ('image/png', 'image/gif', 'image/jpeg', 'image/jpg', 'image/bmp')"
        }
    }

    return { status: true };
}

export function validateText(text) {
    let result = 1;

    if (text == null || text == undefined || text.replace(/\s+/g, '') == '') {
        result = 0;
    }
    return result;
}

export function validateNumber(number) {
    let result = 1;
    if (number == null || number == 0 || number == undefined) {
        result = 0;
    }
    return result;
}

export function SplitText(text, index) {
    let res = text;
    if (text.length < index) {
        return `${text}...`;
    }
    res = res.substring(0, index);
    return `${res}...`;
}

export function RewriteUrl(id, friendly, type, id2 = 0, friendly2 = "") {
    if (type == 1) {
        return `/danh-muc-tin-tuc/${friendly}?g=${id}`
    }
    else if (type == 2) {
        return `/tin-tuc/${friendly}?n=${id}`
    }
    else if (type == 3) {
        return `/video/${friendly}?v=${id}`
    }
    else if (type == 4) {
        return `/danh-muc-du-an/${friendly}-gp${id}`
    }
    else if (type == 5) {
        return `/video-clip/${friendly}-vc${id}`
    }
    else if (type == 6) {
        return `/detail-clip/${friendly}-c${id}/${friendly2}-vd${id2}`;
    }
    else if (type == 7) {
        return `/truyen-hai-huoc/${friendly}-tr${id}`
    }
    else {
        return `/du-an/${friendly}-p${id}`
    }
}


export function FormatMoney(price) {
    try {
        // var nf = price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');  // 12,345.67
        var nf = price.toLocaleString('en-US');
        return `${nf}`
    } catch (e) {
        console.log(e)
        return price;
    }
}

export function AddSeo(seoTitle, seoKey, seoDes) {
    var setting = localStorage.getItem("DATA_SETTING")
    if (setting) {
        var st = JSON.parse(setting);
        const el = document.querySelector('title');
        el.innerText = seoTitle ? ` ${st.name}>${seoTitle}` : ` ${st.name}>${st.metaKeyWord}`;
        const el2 = document.querySelector("meta[name='keywords']");
        el2.setAttribute('content', seoKey || st.metaKeyWord)
        const el3 = document.querySelector("meta[name='description']");
        el3.setAttribute('content', seoDes || st.metaDescription)

    }

}

