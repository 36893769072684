import React, { Component } from 'react'

export default class TopMenu extends Component {
    render() {
        return (
            <nav className="main-header navbar navbar-expand navbar-white navbar-light  ">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" role="button"><i className="fa fa-bars" aria-hidden="true"></i></a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <a href="/" className="nav-link">Home</a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <a className="nav-link">{this.props.subMenu || 'no menu'}</a>
                    </li>
                </ul>

                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" role="button">
                            <i className="fa fa-arrows-alt" ></i>
                        </a>
                    </li>
                </ul>
            </nav>
        )
    }
}
