import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import rootReducer from '../reducers/reducer-root';
import { rootSaga } from './../saga/index';
import createSagaMiddleware from 'redux-saga';

const sagaMiddleware = createSagaMiddleware();
const middleware = [...getDefaultMiddleware({ thunk: false, serializableCheck: false }), sagaMiddleware];

export default configureStore({
    reducer: rootReducer,
    middleware: middleware,

}
)
sagaMiddleware.run(rootSaga);

