import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import React, { useEffect, memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onGetDataSettingWeb } from './../../reducers/main-slice';
import { onGetList } from './../../../src/area/client/home/reducer'
import { AddSeo } from './../../commons/utils'
import Footer from './footer';
export default function LayoutIndex(prop) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(onGetDataSettingWeb());
        AddSeo();
        onGetBrand();
    }, []);
    let { filter, dataBrand, type } = useSelector((state) => state.clientHomeSlice.toJS());
    const onGetBrand = () => {
        dispatch(onGetList(filter));
    }
    return (
        <div>
            <NavMenu prop={prop} />
            <Container className='body-content' >
                {prop.children}

            </Container>
            <Footer></Footer>
        </div>
    )
}
