
import axios from 'axios';


export const API_DOMAIN = "";//"https://localhost:44398"
export async function getToken() {
    // get the existing token from localstorage
    try {
        var tk = localStorage.getItem("accessToken")
        if (!tk) {
            // history.push("/login");
            window.location.href = "/login";
        }
        const jwtPayload = JSON.parse(window.atob(tk.split('.')[1]))
        // create a timestamp for now down to the second
        const now = Math.floor(Date.now() / 1000);
        // Check if the expires timestamp is less than now - meaning it's in the past and has expired
        if (now > jwtPayload.exp) {
            var url = API_DOMAIN + '/api/admin/refresh-token'
            const res = await axios.post(url, {
                "accessToken": tk,
                "refreshToken": localStorage.getItem("refreshToken")
            });
            if (res.data.isError) {
                localStorage.clear();

                // history.push("/login");
                // return undefined;
                window.location.href = "/login";
            }
            else {
                localStorage.setItem('accessToken', res.data.data.tokenKey);
                localStorage.setItem('refreshToken', res.data.data.RefreshToken);
                //return res.data.data.tokenKey;
                return {
                    Authorization: `bearer ${res.data.data.tokenKey}`
                }
            }
        }
        else {
            return {
                Authorization: `bearer ${tk}`
            }
        }


    } catch (exx) {
        console.log("err tk", exx)
        // return undefined;
        window.location.href = "/login";
    }

};
export const menuLists = [
    {
        "id": 1,
        "isOpen": true,
        "title": "Quản lý tin tức",
        "iconClass": "far fa-newspaper",
        "path": "",
        "exact": true,
        "sort": 0,
        "child": [
            {
                "isOpen": false,
                "title": " Danh sách tin tức",
                "iconClass": "",
                "path": "/admin/news/list",
                "exact": true,
                "sort": 0,
            },
            {
                "isOpen": false,
                "title": "Danh mục tin tức",
                "iconClass": "",
                "path": "/admin/news/group",
                "exact": true,
                "sort": 1,
            }
        ]
    },
    {
        "id": 2,
        "isOpen": false,
        "title": "Quản lý người dùng",
        "iconClass": "fas fa-user",
        "path": "",
        "exact": true,
        "sort": 0,
        "child": [
            {
                "isOpen": false,
                "title": "Danh sách người dùng",
                "iconClass": "",
                "path": "/admin/user/list",
                "exact": true,
                "sort": 0,
            },
            {
                "isOpen": false,
                "title": "Quản lý nhóm người dùng",
                "iconClass": "",
                "path": "/admin/user/group",
                "exact": true,
                "sort": 1,
            }
        ]
    },
    {
        "id": 3,
        "isOpen": false,
        "title": "Quản lý sản phẩm",
        "iconClass": "fa fa-folder-open",
        "path": "",
        "exact": true,
        "sort": 0,
        "child": [
            {
                "isOpen": false,
                "title": "Danh sách sản phẩm",
                "iconClass": "",
                "path": "/admin/product/list",
                "exact": true,
                "sort": 0,
            },
            {
                "isOpen": false,
                "title": "Danh mục sản phẩm",
                "iconClass": "",
                "path": "/admin/category/list",
                "exact": true,
                "sort": 1,
            },
            {
                "isOpen": false,
                "title": "Danh mục nhà sản xuất",
                "iconClass": "",
                "path": "/admin/manufacture/list",
                "exact": true,
                "sort": 1,
            },
            {
                "isOpen": false,
                "title": "Danh mục thương hiệu",
                "iconClass": "",
                "path": "/admin/brand/list",
                "exact": true,
                "sort": 1,
            }
        ]
    },
    {
        "id": 4,
        "isOpen": false,
        "title": "Quản lý đơn hàng",
        "iconClass": "fa fa-shopping-cart",
        "path": "",
        "exact": true,
        "sort": 0,
        "child": [
            {
                "isOpen": false,
                "title": "Danh sách đơn hàng",
                "iconClass": "",
                "path": "/admin/order/list",
                "exact": true,
                "sort": 0,
            },
            {
                "isOpen": false,
                "title": "Danh sách phản hồi",
                "iconClass": "",
                "path": "/admin/contact/list",
                "exact": true,
                "sort": 0,
            }

        ]
    },
    {
        "id": 5,
        "isOpen": false,
        "title": "Cấu hình hệ thống",
        "iconClass": "fas fa-cog",
        "path": "",
        "exact": true,
        "sort": 0,
        "child": [
            {
                "isOpen": false,
                "title": "Cấu hình web",
                "iconClass": "",
                "path": "/admin/setting/web",
                "exact": true,
                "sort": 0,
            },
            {
                "isOpen": false,
                "title": "Danh mục dùng chung",
                "iconClass": "",
                "path": "/admin/setting/code",
                "exact": true,
                "sort": 1,
            }
        ]
    }
]
export async function CallApi(method, url, request, is_not_auth) {
    var tk = "";
    if (!is_not_auth) {
        tk = await getToken();
    }
    if (method.toLowerCase() == "post") {
        return await axios.post(url, request, { headers: tk });
    }
    return await axios.get(url, { headers: tk });
}