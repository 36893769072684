import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    item: {
        "fromName": "",
        "email": "",
        "phone": "",
        "note": "",
        "type": 1
    }, // item table
    id: 0,
    isLoading: false,
    isShowFrom: 0
});

export const contactClientSlice = createSlice({
    name: 'contactClientSlice',
    initialState: initialState,
    reducers: {
        updateFieldSingle: function (state, action) {
            return state.set(action.payload.fieldName, action.payload.fieldValue);
        },
        updateFieldInModel: (state, action) => {
            return state.updateIn([action.payload.nameModel, action.payload.fieldName], () => action.payload.fieldValue);
        },
        updateArray: function (state, action) {
            return state.set(action.payload.fieldName, Immutable.fromJS(action.payload.fieldValue));
        },
        onSaveChange: (state) => { return state },

    }
})

export const { updateFieldSingle, updateFieldInModel, updateArray, onSaveChange } = contactClientSlice.actions
export default contactClientSlice.reducer;