import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import React, { useEffect, memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { onGetDataSettingWeb } from '../../reducers/main-slice';
import { onGetList, onGetListBestSellerProduct } from './../../../src/area/client/home/reducer'
import { AddSeo, FormatMoney } from '../../commons/utils'
import Footer from './footer';
import { NavLink } from 'react-router-dom';
export default function LayoutProduct(prop) {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(onGetDataSettingWeb());
        AddSeo();
        onGetProductSeller()
        // onGetBrand();
    }, []);
    let { filter, dataBrand, type, listBestSeller } = useSelector((state) => state.clientHomeSlice.toJS());
    const onGetBrand = () => {
        dispatch(onGetList(filter));
    }
    const onGetProductSeller = () => {
        dispatch(onGetListBestSellerProduct());
    }
    return (
        <div>
            <NavMenu prop={prop} />
            <Container className='body-content'>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="toll-bar-1">
                            <ul className="navigation">
                                <li>
                                    <a href="/">Trang chủ</a>
                                </li>
                                {
                                    type == 1 ?
                                        <li>
                                            <span className='bre-icon'>&rsaquo;</span>
                                            <a className={507803} href="">
                                                Thương hiệu
                                            </a>
                                        </li> :
                                        <li>
                                            <span className='bre-icon'>&rsaquo;</span>
                                            <a className={507803} href="">
                                                Sản phẩm
                                            </a>
                                        </li>

                                }
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="row">

                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                        <div className="module-mn p-detail">
                            <p className="name">Sản phẩm bán chạy</p>
                            <div className="list-link-module product-hot">
                                <ul>
                                    {
                                        listBestSeller.length > 0 ?
                                            listBestSeller.map((r, j) =>
                                                <li key={j} style={{ display: "flex" }}>
                                                    <div style={{ width: "30%" }} className="p-img">
                                                        <NavLink to={`/san-pham/${r.friendlyUrl}/${r.id}/${r.sku}`}>
                                                            <img className=" " src={`/FileUpload/200/${r.imageUrl}`} alt="" />
                                                        </NavLink>

                                                    </div>
                                                    <div style={{ width: "70%" }} className="p-name">
                                                        <div className='txt-p-name'>
                                                            <NavLink to={`/san-pham/${r.friendlyUrl}/${r.id}/${r.sku}`}>
                                                                {r.productName}
                                                            </NavLink>
                                                        </div>
                                                        <div className='txt-p-price'> <span>{FormatMoney(r.price)} <i>đ</i></span></div>
                                                    </div>

                                                </li>
                                            )
                                            :
                                            <li>
                                                <h6>Không có dữ liệu</h6>
                                            </li>
                                    }

                                </ul>
                            </div>
                        </div>


                    </div>

                    <div className="col-xs-12 col-sm-9 col-md-9 col-lg-9">
                        {prop.children}
                    </div>


                </div>




            </Container>
            <Footer></Footer>
        </div>
    )
}
