import React, { Component } from 'react'

export default class Loading extends Component {
  render() {
    return (
      <div style={{ position: "fixed", top: "40%", left: "50%" }}>
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    )
  }
}
