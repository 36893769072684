import { call, put, takeEvery } from "redux-saga/effects";
import * as c from "../../../../constants/constant";
import { CallApi2, API_DOMAIN, TenantID } from "../../../../commons/apiHelper"
import { fetchPaging, updateFieldSingle, updateArray, onGetList, onGetItem, onSaveChange } from '../reducer'
import { showMessage } from "../../../../commons";
export function* saveItemSaga(action) {
	try {
		yield put(updateFieldSingle({ fieldName: "isShowFrom", fieldValue: 0 }))
		let res = yield CallApi2("post", "/api/product/client-payment", { ...action.payload, TenantID });
		if (res.data && !res.data.isError) {

			showMessage('Thông báo!', 'Đặt hàng thành công', 1);
			localStorage.setItem("DATA_CART", "")
			localStorage.setItem("TOTAL_CART", "0")
			action.payload.clearCart()
		}
		else {
			showMessage('Error!!', res.data.message, 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export default function* lhtSaga() {
	yield takeEvery(onSaveChange.toString(), saveItemSaga);
}
