import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    listData: [],

});

export const clientMenuLeftSlice = createSlice({
    name: 'clientMenuLeftSlice',
    initialState: initialState,
    reducers: {
        updateFieldSingle: function (state, action) {
            return state.set(action.payload.fieldName, action.payload.fieldValue);
        },
        updateFieldInModel: (state, action) => {
            return state.updateIn([action.payload.nameModel, action.payload.fieldName], () => action.payload.fieldValue);
        },
        updateArray: function (state, action) {
            return state.set(action.payload.fieldName, Immutable.fromJS(action.payload.fieldValue));
        },
        onGetItem: (state) => { return state }


    }
})

export const { updateFieldSingle, updateFieldInModel, updateArray, onGetItem } = clientMenuLeftSlice.actions
export default clientMenuLeftSlice.reducer;