import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import * as c from "./../constants/constant";
import { fillCodeValue, updateArrayCode, getAllCodeValue, addItemCodeValue ,getAllCodeGroup,deleteCodeValue} from './../reducers/code/code-slice';
import { showMessage } from "./../commons/common";
export function* sagaGetAllCodeValue(action) {
    const { payload } = action;
    let url = c.API_DOMAIN + "/code-mng/code-value-mng/get-all";
    try {
        const headerParams = yield (c.getToken());
        let res = yield call(axios.post, url, payload, { headers: headerParams });
        if (res.data != null && !res.data.isError) {
            console.log("res.datawr", res.data)
            yield put(fillCodeValue(res.data));

        }
        else {

            showMessage('Error!!', 'There is error in too processors', 2);
        }
    } catch (err) {
        console.log(err);
    }
}

export function* sagaGetAllCodeGroup(action) {
    const { payload } = action;
    let url = c.API_DOMAIN + "/code-mng/code-value-mng/get-all";
    try {
        const headerParams = yield (c.getToken());
        let res = yield call(axios.post, url, payload, { headers: headerParams });
        if (res.data != null && !res.data.isError) {
            console.log("res.data", res.data)
            yield put(updateArrayCode({ fieldName: "codeGroup", fieldValue: res.data.data }));

        }
        else {

            showMessage('Error!!', 'There is error in too processors', 2);
        }
    } catch (err) {
        console.log(err);
    }
}

export function* sagaAddItemCodeValue(action) {
    const { payload } = action;
    let url = c.API_DOMAIN + "/code-mng/code-value-mng/add-or-update";
    try {
        const headerParams = yield (c.getToken());
        let res = yield call(axios.post, url, payload.itemAdd, { headers: headerParams });
        if (res.data != null && !res.data.isError) {
            console.log("res.sagaAddItemCodeValue", res.data)
            yield put(getAllCodeValue(payload.filter))

        }
        else {

            showMessage('Error!!', 'There is error in too processors', 2);
        }
    } catch (err) {
        console.log(err);
    }
}

export function* sagaDelItemCodeValue(action) {
    const { payload } = action;
    let url = c.API_DOMAIN + '/code-mng/code-value-mng/delete';
    try {
        const headerParams = yield (c.getToken());
        let res = yield call(axios.post, url, payload.ids, { headers: headerParams });
        if (res.data != null && !res.data.isError) {
            yield put(getAllCodeValue(payload.filter))
        }
        else {

            showMessage('Error!!', 'There is error in too processors', 2);
        }
    } catch (err) {
        console.log(err);
    }
}




export default function* lhtCodeSaga() {
    yield takeEvery(getAllCodeValue.toString(), sagaGetAllCodeValue);
    yield takeEvery(getAllCodeGroup.toString(), sagaGetAllCodeGroup);
    yield takeEvery(addItemCodeValue.toString(), sagaAddItemCodeValue);
    yield takeEvery(deleteCodeValue.toString(), sagaDelItemCodeValue);
}