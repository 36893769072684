import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    listData: [], // data table
    listGroup: [], // data category
    filter: {
        keyword: "",
        pageIndex: 1,
        pageSize: 10, // Default value
        totalPage: 1,
        totalRecord: 0,
        status: 255,
        category: -1

    },
    reload: false,
    isLoading: false,
    isCheckAll: false,
    arrCheck: [],
    item: {}, // item table
    id: 0,
    isShowFrom: 0,
    isShowFromPermission: 0,
    isShowFromExport: 0,
    isShowFromImport: 0,
    relation: {
    },
    authenticated: false,
    currentUser: {

    },
    submenuText: "default",
    listCode: [],
    listCate: [],
    listBrand: [],
    listManufacture: [],
    settingWeb: {}
});

export const mainSlice = createSlice({
    name: 'mainSlice',
    initialState: initialState,
    reducers: {
        fillAllData: (state, action) => {
            const data = action.payload;
            return state.set('listData', Immutable.fromJS(data.data))
                .updateIn(['filter', 'totalRecord'], () => data.totalItems);
        },
        updateFieldSingle: (state, action) => {
            return state.set(action.payload.fieldName, action.payload.fieldValue);
        },
        updateFieldInModel: (state, action) => {
            return state.updateIn([action.payload.nameModel, action.payload.fieldName], () => action.payload.fieldValue);
        },
        updateArray: (state, action) => {
            return state.set(action.payload.fieldName, Immutable.fromJS(action.payload.fieldValue));
        },
        // side effect
        onGetDataPaging: (state) => {
            return state.set('listData', []).set("isLoading", true)
                .updateIn(['filter', 'totalRecord'], () => 0);
        },
        onGetDataItem: (state) => { return state },
        saveChangeVoid: (state) => { return state },
        upLoadFileToServer: (state) => { return state },
        onLogin: (state) => { return state },
        onGetDataDungChung: (state) => { return state },
        onGetDataDiaChinh: (state) => { return state },
        onGetDataCategory: (state) => { return state },
        onGetDataManufacture: (state) => { return state },
        onGetDataSettingWeb: (state) => { return state },
        onAddSupport: (state) => { return state },

    }
})

export const { fillAllData, updateFieldSingle, updateFieldInModel, updateArray, onGetDataPaging, onGetDataItem, saveChangeVoid, onLogin, onGetDataSettingWeb, upLoadFileToServer, onGetDataDungChung, onGetDataDiaChinh, onGetDataCategory, onGetDataManufacture, onAddSupport } = mainSlice.actions
export default mainSlice.reducer;