import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import newsSlice from './../../src/area/admin/admin-news/reducer';
import newsGroupSlice from './../../src/area/admin/admin-news-group/reducer';
import brandSlice from './../../src/area/admin/admin-brand/reducer';
import contactSlice from './../../src/area/admin/admin-contact/reducer';


import categorySlice from './../../src/area/admin/moduleProducts/admin-category/reducer';
import manufactureSlice from './../../src/area/admin/moduleProducts/admin-manufacture/reducer';
import productSlice from './../../src/area/admin/moduleProducts/admin-product/reducer';
import adminOrderSlice from './../../src/area/admin/moduleProducts/admin-order/reducer';
import adminCrawlSlice from '../area/admin/admin-crawl-data/reducer';
import autoGenSlice from './../../src/area/admin/admin-auto-gen/reducer';
import clientProductSlice from './../../src/area/client/products/reducer';
import Main from './main-slice';
import CodeSlice from './code/code-slice';
import clientMenuLeftSlice from './../../src/area/client/menu-bar/reducer';
import clientHomeSlice from './../../src/area/client/home/reducer';
import paymentClientSlice from './../../src/area/client/payment/reducer';
import contactClientSlice from './../../src/area/client/contact/reducer';
import clientNewsSlice from './../../src/area/client/news/reducer';
import clientBrandSlice from './../../src/area/client/brand/reducer';

const rootReducer = combineReducers({
    routing: routerReducer,
    newsSlice,
    newsGroupSlice,
    brandSlice,
    autoGenSlice,
    Main,
    CodeSlice,
    categorySlice,
    manufactureSlice,
    productSlice,
    adminOrderSlice,
    adminCrawlSlice,
    contactSlice,
    clientProductSlice,
    clientMenuLeftSlice,
    clientHomeSlice,
    paymentClientSlice,
    contactClientSlice,
    clientNewsSlice,
    clientBrandSlice

});

export default rootReducer;