import React, { Component } from 'react'
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateFieldSingle, onLogin } from './../reducers/main-slice';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            password: '',
            rememberMe: true

        }
    }
    onLogin() {
        this.setState({
			isSubmit: true
		})
        let { userName, password, rememberMe } = this.state;
        if (!userName || !password) {
			return;
		}
        let payload = {
            "userName": userName,
            "password": password,
            "rememberMe": rememberMe,
            "history": this.props.history
        };
        this.props.onLogin(payload);
        this.setState({
			isSubmit: false
		})
    }
    componentDidMount() {
        var currentPath = this.props.location.state;
        let dataUser = localStorage.getItem('accessToken');
        if (dataUser) {
            var profile = localStorage.getItem('profile');
            if (profile) {
                this.props.updateFieldSingle({ fieldName: "currentUser", fieldValue: JSON.parse(profile) })
            }
            this.props.updateFieldSingle({ fieldName: "authenticated", fieldValue: true });
            if( this.props.history && currentPath)
            {
                this.props.history.push(currentPath.from)

            }
        }
    }
    onUpdateField(field, e) {
        switch (field) {
            case "userName":
                this.setState({
                    userName: e.target.value
                })
                break;
            case "password":
                this.setState({
                    password: e.target.value
                })
                break;
            case "rememberMe":
                this.setState({
                    rememberMe: e.target.value
                })
                break;
            default:
                break;
        }
    }
    render() {
        let { userName, password, rememberMe, isSubmit } = this.state;
        return (
            <div className="hold-transition1 login-page">
                <div className="login-box1">
                    <div className="card-body login-card-body">
                        <div className="card-header text-center">
                            <h3 style={{textTransform:"uppercase"}}>Đăng nhập hệ thống</h3>
                        </div>
                        <div className="card-body">
                            <p className="login-box-msg">Sign in to start your session</p>
 
                                <div className="input-group mb-3">
                                    <input
                                        onChange={this.onUpdateField.bind(this, "userName")}
                                        className={!userName && isSubmit ? "form-control is-invalid" : "form-control"}
                                        placeholder="Tên đăng nhập"
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-envelope"></span>
                                        </div>
                                    </div>
                                    <div className="invalid-feedback">
                                        Thông tin không hợp lệ
                                    </div>
                                </div>
                                <div className="input-group mb-3">
                                    <input type="password"
                                        onChange={this.onUpdateField.bind(this, "password")}
                                        className={!password && isSubmit ? "form-control is-invalid" : "form-control"}
                                        placeholder="Password"
                                    />
                                    <div className="input-group-append">
                                        <div className="input-group-text">
                                            <span className="fas fa-lock"></span>
                                        </div>
                                    </div>
                                    <div className="invalid-feedback">
                                        Thông tin không hợp lệ
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-7">
                                        <div className="icheck-primary">
                                            <input type="checkbox" onClick={this.onUpdateField.bind(this, "rememberMe")}   defaultChecked />
                                            <label style={{marginLeft:10}}>  Ghi nhớ </label>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <button type="submit" onClick={this.onLogin.bind(this)} className="btn btn-primary btn-block"><i className="fas fa-sign-in"></i> Đăng nhập</button>
                                    </div>
                                </div>
                          
                        </div>
                    </div>
                </div>
                
            </div>
        )
        
    }
}

function mapStateToProps(state) {
    const { Main } = state;
    return { Main };
}
function mapDispatchToProps(dispatch) {

    return bindActionCreators({ onLogin, updateFieldSingle }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
