import { call, put, takeEvery } from "redux-saga/effects";
import * as c from "./../../../../constants/constant";
import { CallApi } from "./../../../../commons/apiHelper"
import { fetchPaging, updateFieldSingle, updateArray, onGetList, onGetItem, onSaveChange } from './../reducer'
import { showMessage } from "./../../../../commons";
export function* getListSaga(action) {
	let url = c.API_DOMAIN + `/api/news/GetData`;
	yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
	try {
		let res = yield call(CallApi("post", url, action.payload));
		if (res.data != null && !res.data.isError) {
			yield put(fetchPaging(res.data));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* getItemSaga(action) {
	try {
		let url = c.API_DOMAIN +`/setting/get-table`;
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
		let res = yield CallApi("get", url,null,true);
		if (res.data != null && !res.data.isError) {
			yield put(updateArray({ fieldName: "listData", fieldValue: res.data.data }));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* saveItemSaga(action) {
	var url =   c.API_DOMAIN +`/setting/auto-gen`;  
	try {
		let res = yield CallApi("post", url, action.payload,true);
		if (res.data && !res.data.isError) {
			
			showMessage('Success!', 'Action success', 1);
		}
		else {
			showMessage('Error!!', res.data.message, 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export default function* lhtSaga() {
	yield takeEvery(onGetList.toString(), getListSaga);
	yield takeEvery(onGetItem.toString(), getItemSaga);
	yield takeEvery(onSaveChange.toString(), saveItemSaga);
}
