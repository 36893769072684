import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';
const initialState = Immutable.fromJS({
    listGroup: [],
    dataCrawl: [],
    isLoading: false,
    item: {
        linkCrawl: "https://dantri.com.vn/xa-hoi/giao-thong.htm",
        xPathBlock: "//body/main[1]/div[3]/div[1]/div[1]/article",
        xPathTitle: "//body/main[1]/div[3]/div[1]/div[1]/article[{0}]/div[2]/h3/a",
        xPathImage: "//body/main[1]/div[3]/div[1]/div[1]/article[{0}]/div[1]/a/img",
        xPathSum: "//body/main[1]/div[3]/div[1]/div[1]/article[{0}]/div[2]/div/a",
        xPathDes: "//body/main[1]/div[1]/div[2]/div[1]/article[1]/div[3]",
        groupID: -1,
        top: 3
    },
    arrCheck: [],
    reload: false,
    groupID: -1,
    id: 0,
    isCheckAll: false,
});

export const adminCrawlSlice = createSlice({
    name: 'adminCrawlSlice',
    initialState: initialState,
    reducers: {
        updateFieldSingle: function (state, action) {
            return state.set(action.payload.fieldName, action.payload.fieldValue);
        },
        updateFieldInModel: (state, action) => {
            return state.updateIn([action.payload.nameModel, action.payload.fieldName], () => action.payload.fieldValue);
        },
        updateArray: function (state, action) {
            return state.set(action.payload.fieldName, Immutable.fromJS(action.payload.fieldValue));
        },
        // side effect
        startCrawl: (state) => { return state },
        fillCrawl: (state) => { return state },
        setCheckBox: (state) => { return state },
        setCheckBoxItem: (state) => { return state },

    }
})
export const { updateFieldSingle, updateFieldInModel, updateArray, startCrawl, fillCrawl, setCheckBox, setCheckBoxItem } = adminCrawlSlice.actions
export default adminCrawlSlice.reducer;