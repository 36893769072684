import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Route, Redirect } from 'react-router-dom';

class PublicRoute extends React.Component {

    render() {
        const { RouteComp, authenticated, path, layout, ...rest } = this.props;
        const previousLocation = this.props.routing.location;
        return (
            <Route {...rest}
                render={props =>
                    typeof authenticated === undefined || authenticated == false
                        ? <RouteComp {...props} />
                        : <Redirect to={previousLocation && path === '/login' && !authenticated ? previousLocation : '/'} />
                }
            />
        )
    }
}

function mapStateToProps(state) {
    const { routing } = state;
    return { routing };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoute)