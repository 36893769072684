import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import store from './store/store';
import { Provider } from 'react-redux';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const history = createBrowserHistory({ basename: baseUrl });
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<Provider store={store}>
  <BrowserRouter history={history}>
    <App />
  </BrowserRouter>
</Provider>);
registerServiceWorker();

