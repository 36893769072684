import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import TopBar from './top-bar';
import { useDispatch, useSelector } from 'react-redux';
export default function NavMenu(prod) {
    let { settingWeb } = useSelector((state) => state.Main.toJS());
    const [collapsed, SetCollapsed] = useState(true)
    const toggleNavbar = () => {
        SetCollapsed(!collapsed)
    }

    return (
        <header>
            {/* <div className='top-line-menu' >
                <TopBar />
            </div> */}
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 fixed-top" container light style={{ background: "#FFF" }}>
                <NavbarToggler onClick={() => toggleNavbar()} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow" style={{ justifyContent: "space-around", width: "100%" }} >
                        <NavItem>
                            {/* <NavLink tag={Link} className="text-dark item-menu" to="/">Trang chủ</NavLink> */}
                            <NavLink exact={true} className="nav-link  item-menu" activeClassName="active" to={"/"}>Trang chủ</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink exact={true} className="nav-link  item-menu" activeClassName="active" to="/gioi-thieu">Giới thiệu</NavLink>
                        </NavItem>
                        {/* <NavItem>
                            <NavLink exact={true} className="nav-link  item-menu" activeClassName="active" to="/san-pham">Sản phẩm</NavLink>
                        </NavItem> */}
                        <NavItem>
                            <NavLink exact={true} className="nav-link  item-menu" activeClassName="active" to="/the-manh">Thế mạnh</NavLink>
                        </NavItem>
                        <NavItem className='area-logo'>
                            <NavLink tag={Link} className="text-dark item-menu item-logo" to="/">
                                <img src={`/FileUpload/200/${settingWeb.logo}`} />

                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink exact={true} className="nav-link  item-menu" activeClassName="active" to="/tin-tuc">Tin tức</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink exact={true} className="nav-link  item-menu" activeClassName="active" to="/tuyen-dung">Tuyển dụng</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink exact={true} className="nav-link   item-menu" activeClassName="active" to="/lien-he">Liên hệ</NavLink>
                        </NavItem>
                        {/* <li>
                            <TopBar prods={prod} />
                        </li> */}
                    </ul>
                </Collapse>
            </Navbar>
        </header>
    )
}
