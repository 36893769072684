import axios from "axios";
import { call, put, takeEvery } from "redux-saga/effects";
import * as c from "./../constants/constant";
import { onAddSupport, fillAllData, updateFieldSingle, onGetDataSettingWeb, updateArray, onGetDataPaging, onGetDataItem, saveChangeVoid, onLogin, onGetDataDungChung, onGetDataDiaChinh, upLoadFileToServer } from './../reducers/main-slice'
import { showMessage } from "./../commons/common";
import { TenantID } from "./../commons/apiHelper"
export function* sagaProcessDataPaging(action) {
	let { para, url, search } = action.payload;
	try {
		const headerParams = yield (c.getToken());
		// yield delay(100000);
		let res = yield call(axios.post, c.API_DOMAIN + url, para, { headers: headerParams });
		if (res.data != null && !res.data.isError) {
			yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));

			if (search) {
				yield put(fillAllData(res.data));
			}
			else {
				yield put(updateArray({ fieldName: 'listGroup', fieldValue: res.data.data }));
			}
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		yield put(updateFieldSingle({ fieldName: 'authenticated', fieldValue: false }));
		return;

	}
}
export function* sagaProcessDataItem(action) {
	let { url, nameObject, isAlert } = action.payload;
	try {
		const headerParams = yield (c.getToken());
		url = c.API_DOMAIN + url;

		let res = yield call(axios.get, url, { headers: headerParams });
		if (res.data != null && !res.data.isError) {
			if (nameObject) {
				yield put(updateArray({ fieldName: nameObject, fieldValue: res.data.data }));
			}
			if (isAlert) {
				showMessage('Success!', res.data.message, 1);
			}
		}
		else {

			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
}
export function* sageSaveChangeVoid(action) {
	let { para, url, filter } = action.payload;
	try {
		const headerParams = yield (c.getToken());
		url = c.API_DOMAIN + url;
		let res = yield call(axios.post, url, para, { headers: headerParams });
		if (res.data && !res.data.isError) {
			yield put(updateFieldSingle({ fieldName: "isShowFrom", fieldValue: 0 }))
			if (filter) {

				var payload = {
					para: filter,
					url: filter.url,
					search: true
				}
				yield put(onGetDataPaging(payload));
				yield put(updateArray({ fieldName: 'item', fieldValue: {} }));

			}
			showMessage('Success!', 'Thành công', 1);
		}
		else {
			showMessage('Error!!', res.data.message, 2);
		}
	} catch (err) {
		showMessage('Error sys!', err, 2);
	}
}

export function* sagaUploadImg(action) {
	const url = c.API_DOMAIN + "/api/uploads-media";
	const { payload } = action;
	var formData = new FormData();
	formData.append('battlePlans', payload.data);
	try {
		//const headerParams = yield (c.getToken());
		let res = yield call(axios.post, url, formData);
		if (res.data != null) {
			//yield put(updateFieldInModel({ nameModel: 'item', fieldName: payload.field, fieldValue: res.data.data }))
			if (payload.callback) {
				payload.callback(res.data.url);
			}

		}
		else {

			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		showMessage('Error sys!', err, 2);
	}
}
export function* sagaOnLogin(action) {
	let { payload } = action;
	const url = `${c.API_DOMAIN}/api/admin/login`;
	try {
		let res = yield call(axios.post, url, payload);
		if (res.data != null && !res.data.isError) {
			yield put(updateFieldSingle({ fieldName: "currentUser", fieldValue: res.data.data }))
			yield put(updateFieldSingle({ fieldName: "authenticated", fieldValue: true }))
			localStorage.setItem('accessToken', res.data.data.tokenKey);
			localStorage.setItem('refreshToken', res.data.data.refreshToken);
			localStorage.setItem('profile', JSON.stringify(res.data.data))

			if (!localStorage.getItem("DATA_CATE")) {
				yield getCate();
			}
			else {
				let dc = localStorage.getItem("DATA_CATE");
				yield put(updateArray({ fieldName: 'listCate', fieldValue: JSON.parse(dc) }));

			}
			if (!localStorage.getItem("DATA_FACTURE")) {
				yield getManufacture();
			}
			else {
				let dc = localStorage.getItem("DATA_FACTURE");
				yield put(updateArray({ fieldName: 'listManufacture', fieldValue: JSON.parse(dc) }));

			}
			if (!localStorage.getItem("DATA_BRAND")) {
				yield getBrand();
			}
			else {
				let brands = localStorage.getItem("DATA_BRAND");
				yield put(updateArray({ fieldName: 'listBrand', fieldValue: JSON.parse(brands) }));

			}
			debugger;
			if (!localStorage.getItem("DATA_COMMON")) {
				yield sagaGetDataCode();
			}
			else {
				let commonData = localStorage.getItem("DATA_COMMON");
				yield put(updateArray({ fieldName: 'listCode', fieldValue: JSON.parse(commonData) }));

			}
			// payload.history.push("/admin/news/list");
			window.location.href = "/admin/news/list";

		}
		else {
			showMessage('Error sys!', "Sai thông tin đăng nhập", 2);
			//showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("err", err)
		showMessage('Error sys!', err, 2);
	}
}

export function* sagaGetDataCode() {
	// let { } = action.payload;
	try {
		const headerParams = yield (c.getToken());
		var url = c.API_DOMAIN + "/code-mng/code-value-mng/get-all";
		var filter = {
			keyword: "",
			pageIndex: 1,
			pageSize: 100000, // Default value
			totalPage: 1,
			totalRecord: 0,
			status: -1,
			category: -1
		}
		let res = yield call(axios.post, url, filter, { headers: headerParams });
		debugger;
		if (res.data && !res.data.isError) {
			localStorage.setItem("DATA_COMMON", JSON.stringify(res.data.data))
			yield put(updateArray({ fieldName: 'listCode', fieldValue: res.data.data }));
		}
		else {

		}
	} catch (err) {

	}
}
export function* sagaGetDataDiaChinh() {
	// let { } = action.payload;
	try {
		const headerParams = yield (c.getToken());
		var url = c.API_DOMAIN + "/api/get-data-dia-chinh";
		let res = yield call(axios.get, url, { headers: headerParams });
		if (res.data && !res.data.isError) {
			localStorage.setItem("DATA_DIA_CHINH", JSON.stringify(res.data.data))
			yield put(updateArray({ fieldName: 'dataDiaChinh', fieldValue: res.data.data }));
		}
		else {

		}
	} catch (err) {

	}
}
export function* getCate() {
	try {
		const headerParams = yield (c.getToken());
		var url = c.API_DOMAIN + "/api/category/get-dropdown";

		var filter = {
			keyword: "",
			pageIndex: 1,
			pageSize: 100000, // Default value
			totalPage: 1,
			totalRecord: 0,
			status: -1,
			category: -1
		}
		let res = yield call(axios.post, url, filter, { headers: headerParams });
		if (res.data && !res.data.isError) {
			localStorage.setItem("DATA_CATE", JSON.stringify(res.data.data))
			yield put(updateArray({ fieldName: 'listCate', fieldValue: res.data.data }));
		}
		else {

		}
	} catch (err) {

	}
}

export function* getBrand() {
	try {
		const headerParams = yield (c.getToken());
		var url = c.API_DOMAIN + "api/brand/get-all";

		var filter = {
			keyword: "",
			pageIndex: 1,
			pageSize: 1000, // Default value
			totalPage: 1,
			totalRecord: 0,
			status: -1,
			category: -1
		}
		let res = yield call(axios.post, url, filter, { headers: headerParams });
		if (res.data && !res.data.isError) {
			localStorage.setItem("DATA_BRAND", JSON.stringify(res.data.data))
			yield put(updateArray({ fieldName: 'listBrand', fieldValue: res.data.data }));
		}
		else {

		}
	} catch (err) {

	}
}
export function* getManufacture() {
	try {
		const headerParams = yield (c.getToken());
		var url = c.API_DOMAIN + "/api/manufacture/get-all";

		var filter = {
			keyword: "",
			pageIndex: 1,
			pageSize: 10000, // Default value
			totalPage: 1,
			totalRecord: 0,
			status: -1,
			category: -1
		}
		let res = yield call(axios.post, url, filter, { headers: headerParams });
		if (res.data && !res.data.isError) {
			localStorage.setItem("DATA_FACTURE", JSON.stringify(res.data.data))
			yield put(updateArray({ fieldName: 'listManufacture', fieldValue: res.data.data }));
		}
		else {

		}
	} catch (err) {

	}
}

export function* getSettingWeb() {
	try {
		var url = `${c.API_DOMAIN}/setting/get-item-client/${TenantID}`;
		let res = yield call(axios.get, url);
		if (res.data && !res.data.isError) {
			localStorage.setItem("DATA_SETTING", JSON.stringify(res.data.data))
			yield put(updateArray({ fieldName: 'settingWeb', fieldValue: res.data.data }));
			document.title = `Welcome | ${res.data.data.metaTitle}`;
			document.getElementsByTagName("META")[3].content = `${res.data.data.metaKeyWord}`
			document.getElementsByTagName("META")[4].content = `${res.data.data.metaDescription}`
			var st = res.data.data;
			const script = document.createElement("script");
			script.src = st.faceBook;
			script.crossOrigin = "anonymous";
			script.nonce = "ZPMDqS7u"
			script.async = true;
			script.defer = true;
			document.body.appendChild(script);
			// FB.init({
			// 	appId: '488115588251817',
			// 	status: true, // check login status
			// 	cookie: true, // enable cookies to allow the server to access the session
			// 	xfbml: true  // parse XFBML
			// });
			// debugger
			const FB = window.FB;
			FB.XFBML.parse();
		}
		else {

		}

	} catch (err) {

	}
}

export function* sagaAddSupport(action) {
	let url = "/api/support/add-news-contact";
	try {
		const headerParams = yield (c.getToken());
		// yield delay(100000);
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
		let res = yield call(axios.post, url, action.payload);
		if (res.data != null && !res.data.isError) {

		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		return;

	}
}


export default function* lhtSaga() {
	yield takeEvery(onGetDataPaging.toString(), sagaProcessDataPaging);
	yield takeEvery(onGetDataItem.toString(), sagaProcessDataItem);
	yield takeEvery(saveChangeVoid.toString(), sageSaveChangeVoid);
	yield takeEvery(upLoadFileToServer.toString(), sagaUploadImg);
	yield takeEvery(onLogin.toString(), sagaOnLogin);
	yield takeEvery(onGetDataDiaChinh.toString(), sagaGetDataDiaChinh);
	yield takeEvery(onGetDataSettingWeb.toString(), getSettingWeb);
	yield takeEvery(onAddSupport.toString(), sagaAddSupport);
}
