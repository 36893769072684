import { call, put, takeEvery } from "redux-saga/effects";
import * as c from "./../../../../../constants/constant";
import { CallApi } from "./../../../../../commons/apiHelper"
import { fetchPaging, updateFieldSingle, updateArray, onGetList, onGetItem, onSaveChange, onGetByCategory } from './../reducer'
import { showMessage } from "./../../../../../commons";
export function* getListSaga(action) {
	let url = c.API_DOMAIN + `/api/product/get-all`;
	yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
	try {
		let res = yield CallApi("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			yield put(fetchPaging(res.data));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* getItemSaga(action) {
	try {
		let { id, cb } = action.payload;
		let url = c.API_DOMAIN + `/api/product/get-item/${id}`;
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
		let res = yield CallApi("get", url);
		if (res.data != null && !res.data.isError) {
			let { item, imgs, attributes, variants } = res.data.data;
			yield put(updateFieldSingle({ fieldName: "isShowFrom", fieldValue: 1 }));
			yield put(updateArray({ fieldName: "item", fieldValue: item[0] }));
			yield put(updateArray({ fieldName: "imgs", fieldValue: imgs }));
			yield put(updateArray({ fieldName: "attributes", fieldValue: attributes }));
			yield put(updateArray({ fieldName: "variants", fieldValue: variants }));

			let fileList = []
			for (let index = 0; index < imgs.length; index++) {
				const element = imgs[index];
				fileList.push({
					uid: index,
					percent: 50,
					name: element.ImgSource,
					status: 'done',
					url: `/FileUpload/200/${element.ImgSource}`,
				})
			}
			yield put(updateArray({ fieldName: "fileList", fieldValue: fileList }));



		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* saveItemSaga(action) {
	let { req, filter, url } = action.payload;
	try {
		let res = yield CallApi("post", url, req);
		if (res.data && !res.data.isError) {
			yield put(updateFieldSingle({ fieldName: "isShowFrom", fieldValue: 0 }))
			if (filter) {
				yield put(onGetList(filter));
				yield put(updateArray({ fieldName: 'item', fieldValue: {} }));
				yield put(updateArray({ fieldName: 'imgs', fieldValue: [] }));
				yield put(updateArray({ fieldName: 'fileList', fieldValue: [] }));

			}
			showMessage('Success!', 'Action success', 1);
		}
		else {
			showMessage('Error!!', res.data.message, 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* getByCategorySaga(action) {
	try {
		let { parentId, tableName, modelName, } = action.payload;
		let url = c.API_DOMAIN + `/api/product/get-list-item/${parentId}/${tableName}`;
		//yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
		let res = yield CallApi("get", url);
		if (res.data != null && !res.data.isError) {
			var categoryProperties = [];
			var tree = []
			var dataResponse = res.data.data;
			categoryProperties = [...new Set(dataResponse.map(item => item.CatalogPropertyId))]
			categoryProperties.forEach((element, j) => {
				var items = dataResponse.filter(x => x.CatalogPropertyId == element);
				if (items) {
					var child = {
						title: items[0].Name,
						key: element
					};
					var children = [];
					items.forEach((xx, j) => {
						children.push({
							title: xx.Value,
							key: xx.CatalogPropertyValueId,
							row: xx
						})
					});
					child.children = children;
					tree.push(child)
				}
			});
			yield put(updateArray({ fieldName: modelName, fieldValue: tree }));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export default function* lhtSaga() {
	yield takeEvery(onGetList.toString(), getListSaga);
	yield takeEvery(onGetItem.toString(), getItemSaga);
	yield takeEvery(onSaveChange.toString(), saveItemSaga);
	yield takeEvery(onGetByCategory.toString(), getByCategorySaga);
}
