import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    listData: [],
    listGroup: [],
    filter: {
        keyword: "",
        pageIndex: 1,
        pageSize: 10000, // Default value
        totalPage: 1,
        totalRecord: 0,
        status: 1,
        category: -1
    },
    isLoading: false,
    isShowFrom: 0,
    item: {},
    product: {},
    dataRelation: [],
    dataHighLigh: [],
    productImages: [],
    variantSelected: {},
    attributes: [],
    variants: [],
    cart: [],
    totalCart: 0,
    listProductByCate: [],
    dataBrand: [],
    brandName: '',
    type: 1,//=1 breacumb thương hiệu, =2 sản phẩm

    listDataProduct: [],
    filterProduct: {
        keyword: "",
        pageIndex: 1,
        pageSize: 100000, // Default value
        totalPage: 1,
        totalRecord: 0,
        status: 1,
        category: -1,
        type: -1,
        sortPrice: 1,
        sortAz: 1

    },
    listBestSeller: []
});

export const clientHomeSlice = createSlice({
    name: 'clientHomeSlice',
    initialState: initialState,
    reducers: {
        fetchPaging: function (state, action) {
            const data = action.payload;
            return state.set('listData', Immutable.fromJS(data.data)).set("type", 1)
                .updateIn(['filter', 'totalRecord'], () => data.totalItems);
        },
        fetchPagingProduct: function (state, action) {
            const data = action.payload;
            return state.set('listDataProduct', Immutable.fromJS(data.data)).set("type", 2)
                .updateIn(['filterProduct', 'totalRecord'], () => data.totalItems);
        },
        updateFieldSingle: function (state, action) {
            return state.set(action.payload.fieldName, action.payload.fieldValue);
        },
        updateFieldInModel: (state, action) => {
            return state.updateIn([action.payload.nameModel, action.payload.fieldName], () => action.payload.fieldValue);
        },
        updateArray: function (state, action) {
            return state.set(action.payload.fieldName, Immutable.fromJS(action.payload.fieldValue));
        },
        // side effect
        onGetList: (state) => {
            return state.set('listData', []).set("isLoading", true)
                .updateIn(['filter', 'totalRecord'], () => 0);
        },
        onGetItem: (state) => { return state },
        onSaveChange: (state) => { return state },
        onAddCart: (state, action) => {
            var total = 0;
            var cart = state.get("cart").toJS();
            let product = action.payload;
            var checkExist = cart.find(x => x.sku == product.sku);
            if (!checkExist || cart.length == 0) {
                cart.push(product);
            }
            else {
                for (let index = 0; index < cart.length; index++) {
                    const element = cart[index];
                    if (element.sku == product.sku) {
                        element.qty = parseInt(element.qty || 0) + parseInt(product.qty || 0)
                        element.title = !element.title ? product.title : element.title
                    }
                    total += parseInt(element.qty || 0) * parseInt(element.price || 0)
                }
            }
            localStorage.setItem("DATA_CART", JSON.stringify(cart))
            localStorage.setItem("TOTAL_CART", total)
            return state.set("cart", Immutable.fromJS(cart)).set("totalCart", total);
        },
        onAddCartHome: (state) => { return state },
        onGetProductByBrand: (state) => { return state },
        onGetListProduct: (state) => { return state },
        onGetListBestSellerProduct: (state) => { return state },
    }
})

export const { fetchPaging, updateFieldSingle, updateFieldInModel, updateArray, onGetList, onGetItem, onSaveChange, onAddCart, onAddCartHome, fetchPagingProduct, onGetProductByBrand, onGetListProduct, onGetListBestSellerProduct } = clientHomeSlice.actions
export default clientHomeSlice.reducer;