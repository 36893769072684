import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import TopMenu from './top-menu'
import SidebarMenu from './sidebar-menu'
import './../css/custom.css';
// import './adminlte.css'
// import AnTDLayout from './antd-layout';

class LayoutAdmin extends Component {
	render() {
		let { submenuText, currentUser } = this.props.Main.toJS();
		console.log("submenuText", submenuText)
		return (
			<>
				<TopMenu subMenu={submenuText} />
				<SidebarMenu userName={currentUser.username} path={currentUser.pathUrl} />
				<div className="content-wrapper">
					<section className="content" >
						<div className="box-body" style={{ position: "relative" }}>
							{this.props.children}
						</div>
					</section>
				</div>

			</>
		);
	}
}
function mapStateToProps(state) {
	const { Main } = state;
	return { Main };
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(LayoutAdmin);