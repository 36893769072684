import { call, put, takeEvery } from "redux-saga/effects";
import { CallApi2, API_DOMAIN } from "../../../../commons/apiHelper"
import { fetchPaging, updateFieldSingle, updateArray, onGetList, onGetItem, onSaveChange } from '../reducer'
import { showMessage } from "../../../../commons";
export function* getListSaga(action) {
	let url = API_DOMAIN + `/api/brand/client/get-all`;
	yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
	try {
		let res = yield CallApi2("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			yield put(fetchPaging(res.data));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}

export default function* lhtSaga() {
	yield takeEvery(onGetList.toString(), getListSaga);
}
