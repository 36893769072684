import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    listData: [],
    listGroup: [],
    filter: {
        keyword: "",
        pageIndex: 1,
        pageSize: 10, // Default value
        totalPage: 1,
        totalRecord: 0,
        status: -1,
        category: -1
    },
    isCheckAll: false,
    arrCheck: [],
    item: {}, // item table
    id: 0,
    isLoading: false,
    isShowFrom: 0
});

export const newsSlice = createSlice({
    name: 'newsSlice',
    initialState: initialState,
    reducers: {
        fetchPaging: function (state, action) {
            const data = action.payload;
            return state.set('listData', Immutable.fromJS(data.data))
                .updateIn(['filter', 'TotalRecord'], () => data.totalItems);
        },
        updateFieldSingle: function (state, action) {
            return state.set(action.payload.fieldName, action.payload.fieldValue);
        },
        updateFieldInModel: (state, action) => {
            return state.updateIn([action.payload.nameModel, action.payload.fieldName], () => action.payload.fieldValue);
        },
        updateArray: function (state, action) {
            return state.set(action.payload.fieldName, Immutable.fromJS(action.payload.fieldValue));
        },
        // side effect
        onGetList: (state) => {
            return state.set('listData', []).set("isLoading", true)
                .updateIn(['filter', 'totalRecord'], () => 0);
        },
        onGetItem: (state) => { return state },
        onSaveChange: (state) => { return state },
        onGetCategory: (state) => { return state },

    }
})

export const { fetchPaging, updateFieldSingle, updateFieldInModel, updateArray, onGetList, onGetItem, onSaveChange, onGetCategory } = newsSlice.actions
export default newsSlice.reducer;