import React, { Component, Suspense, lazy } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import './css/custom.css'
import './css/adminlte.min.css'
import Login from './layout/login';
import PrivateRoute from './routers/PrivateRoute';
import PublicRoute from './routers/PublicRoute';
import LayoutAdmin from './layout/Layout-admin';
import LayoutClient from './layout/Layout-client';
import LayoutDetailClient from './layout/Layout-detail-client';
import Loading from './components/base/loading';
import { Route, Redirect } from 'react-router-dom';
import RouterLayoutClient from './routers/RouterLayoutClient';
import RouterLayoutClientDetail from './routers/RouterLayoutClientDetail';
const DashBoard = lazy(async () => import('./area/admin/admin-dashboard/index'));
const NewsMng = lazy(async () => import('./area/admin/admin-news/components'));
const NewsGroupMng = lazy(async () => import('./area/admin/admin-news-group/components'));
const BrandMng = lazy(async () => import('./area/admin/admin-brand/components'));
const UserMng = lazy(async () => import('./area/admin/admin-auth-user/main'));
const GroupMng = lazy(async () => import('./area/admin/admin-auth-group/main'));
const RoleMng = lazy(async () => import('./area/admin/admin-auth-role/main'));
const ModuleMng = lazy(async () => import('./area/admin/admin-auth-module/main'));
const SettingMng = lazy(async () => import('./area/admin/admin-sys-setting/main'));
const CodeMng = lazy(async () => import('./area/admin/admin-code-mng/main'));
const AdminCategoriesPage = lazy(async () => import('./area/admin/moduleProducts/admin-category/components'));
const AdminManufacturePage = lazy(async () => import('./area/admin/moduleProducts/admin-manufacture/components'));
const AdminProductPage = lazy(async () => import('./area/admin/moduleProducts/admin-product/components'));
const AdminOrderPage = lazy(async () => import('./area/admin/moduleProducts/admin-order/components'));
const AdminContactPage = lazy(async () => import('./area/admin/admin-contact/components'));
// const AdminCrawlPage = lazy(async () => import('./area/admin/admin-crawl-data/components'));
const HomePage = lazy(async () => import('./area/client/home/components'));
const AboutPage = lazy(async () => import('./area/client/about/components'));
const StrongPointsPage = lazy(async () => import('./area/client/about/components/strongPoints'));

const TuyenDungPage = lazy(async () => import('./area/client/recruit/components'));

const NewsClientPage = lazy(async () => import('./area/client/news/components'));
const NewsDetailClientPage = lazy(async () => import('./area/client/news/components/detail'));
const ContactPage = lazy(async () => import('./area/client/contact/components'));
const ProductDetailPage = lazy(async () => import('./area/client/home/components/product-detail'));
const ProductByBrandHomePage = lazy(async () => import('./area/client/home/components/product-by-brand'));
const ProductByCategoryPage = lazy(async () => import('./area/client/products/components/list'));


const ProductSearchPage = lazy(async () => import('./area/client/products/components/list-product-search'));
const ClientPaymentPage = lazy(async () => import('./area/client/payment/components'));
const BrandPage = lazy(async () => import('./area/client/brand/components'));
const ProductListClientPage = lazy(async () => import('./area/client/home/components/productList'));

class App extends Component {
	render() {
		let { authenticated } = this.props.Main.toJS();
		return (
			<Router>
				<Switch>
					<Suspense fallback={<Loading />}>
						<Switch>
							<PublicRoute exact path='/login' RouteComp={Login} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/news/list' RouteComp={NewsMng} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/news/group' RouteComp={NewsGroupMng} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/brand/list' RouteComp={BrandMng} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/user/list' RouteComp={UserMng} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/user/group' RouteComp={GroupMng} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/user/role' RouteComp={RoleMng} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/user/module' RouteComp={ModuleMng} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/setting/web' RouteComp={SettingMng} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/setting/code' RouteComp={CodeMng} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/category/list' RouteComp={AdminCategoriesPage} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/manufacture/list' RouteComp={AdminManufacturePage} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/product/list' RouteComp={AdminProductPage} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/contact/list' RouteComp={AdminContactPage} authenticated={authenticated} />
							<PrivateRoute exact={true} path='/admin/order/list' RouteComp={AdminOrderPage} authenticated={authenticated} />
							{/* <PrivateRoute exact={true} path='/admin/crawl' RouteComp={AdminCrawlPage} authenticated={authenticated} /> */}
							<RouterLayoutClient exact={true} path='/' component={HomePage} />
							<RouterLayoutClient exact={true} path='/tin-tuc' component={NewsClientPage} />
							<RouterLayoutClient exact={true} path='/tin-tuc/:slug/:id/' component={NewsDetailClientPage} />
							<RouterLayoutClient exact={true} path='/gioi-thieu' component={AboutPage} />
							<RouterLayoutClient exact={true} path='/the-manh' component={StrongPointsPage} />
							<RouterLayoutClient exact={true} path='/tuyen-dung' component={TuyenDungPage} />
							<RouterLayoutClientDetail exact={true} path='/thuong-hieu' component={BrandPage} />
							<RouterLayoutClient exact={true} path='/lien-he' component={ContactPage} />
							<RouterLayoutClient exact={true} path='/san-pham' component={ProductListClientPage} />
							<RouterLayoutClient exact={true} path='/san-pham/:slug/:id/:sku' component={ProductDetailPage} />
							<RouterLayoutClientDetail exact={true} path='/category/:slug/:id' component={ProductByCategoryPage} />
							<RouterLayoutClientDetail exact={true} path='/thuong-hieu/:slug/:id' component={ProductByBrandHomePage} />
							<RouterLayoutClientDetail exact={true} path='/danh-sach-san-pham/:key' component={ProductSearchPage} />
							<RouterLayoutClient exact={true} path='/thanh-toan' component={ClientPaymentPage} />
						</Switch>
					</Suspense>
				</Switch>
			</Router>
		);
	}
}
function mapStateToProps(state) {
	const { Main } = state;
	return { Main };
}
function mapDispatchToProps(dispatch) {
	return bindActionCreators({}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(App);