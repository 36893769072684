import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    item: {
        attributes: [],
        product: {},
        productImages: [],
        relation: [],
        variants: [],
        optionAttr: [],
        variantSelected: {}
    },
    filter: {
        keyword: "",
        pageIndex: 1,
        pageSize: 10, // Default value
        totalPage: 1,
        totalRecord: 0,
        status: -1,
        category: -1
    },
    listProductByCate: [],
    isLoading: false,
    isShowFrom: 0
});

export const clientProductSlice = createSlice({
    name: 'clientProductSlice',
    initialState: initialState,
    reducers: {
        updateFieldSingle: function (state, action) {
            return state.set(action.payload.fieldName, action.payload.value);
        },
        updateFieldInModel: (state, action) => {
            return state.updateIn([action.payload.nameModel, action.payload.fieldName], () => action.payload.fieldValue);
        },
        updateArray: function (state, action) {
            return state.set(action.payload.fieldName, Immutable.fromJS(action.payload.fieldValue));
        },
        // side effect
        onGetList: (state) => {
            return state.set('listData', []).set("isLoading", true)
                .updateIn(['filter', 'totalRecord'], () => 0);
        },
        onGetItem: (state) => { return state },
        onSaveChange: (state) => { return state },
        onGetProductByCate: (state) => { return state },

    }
})

export const { updateFieldSingle, updateFieldInModel, updateArray, onGetList, onGetItem, onSaveChange, onGetProductByCate } = clientProductSlice.actions
export default clientProductSlice.reducer;