import React, { Component } from "react";
import { Route } from "react-router-dom";
import LayoutDetailClient from '../layout/client/Layout-product';
// import LayoutDetailClient from '../layout/Layout-detail-client';
function RouterLayoutClientDetail({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <LayoutDetailClient> <Component {...matchProps} /></LayoutDetailClient>

            )}
        />
    )
}

export default RouterLayoutClientDetail