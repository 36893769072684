import { call, put, takeEvery } from "redux-saga/effects";
import * as c from "./../../../../constants/constant";
import { CallApi2 } from "./../../../../commons/apiHelper"
import { fetchPaging, updateFieldSingle, updateArray, onGetList, onGetItem, onAddCart, onAddCartHome, onGetProductByBrand, fetchPagingProduct, onGetListProduct, onGetListBestSellerProduct } from './../reducer'
import { showMessage } from "./../../../../commons";
export function* getListSaga(action) {
	let url = c.API_DOMAIN + `/api/brand/client/get-all`;
	yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
	try {
		let res = yield CallApi2("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			yield put(updateArray({ fieldName: "dataBrand", fieldValue: res.data.data }));
			localStorage.setItem("DATA_BRAND", JSON.stringify(res.data.data))
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}

export function* getItemSaga(action) {
	try {
		let { id, sku } = action.payload;
		let url = c.API_DOMAIN + `/api/product/list-data-client-item/${id}/${sku}`;
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
		let res = yield CallApi2("get", url, null);
		if (res.data != null && !res.data.isError) {

			yield put(updateFieldSingle({ fieldName: "isShowFrom", fieldValue: 1 }));
			let { result, dataRelation, dataHighLigh } = res.data.data;
			let { productImages, variants, attributes } = result;

			yield put(updateArray({ fieldName: "product", fieldValue: result }));
			yield put(updateArray({ fieldName: "dataRelation", fieldValue: dataRelation }));
			yield put(updateArray({ fieldName: "dataHighLigh", fieldValue: dataHighLigh }));
			yield put(updateArray({ fieldName: "productImages", fieldValue: productImages }));
			yield put(updateArray({ fieldName: "variants", fieldValue: variants }));
			yield put(updateArray({ fieldName: "attributes", fieldValue: attributes }));
			var variantSelected = variants.find(x => x.isSelected);
			yield put(updateArray({ fieldName: "variantSelected", fieldValue: variantSelected }));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}

export function* getVariantDefaultSaga(action) {
	try {
		let { Id, SKU, ProductName } = action.payload;
		let url = c.API_DOMAIN + `/api/product/client-get-product-default/${Id}/${SKU}`;
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
		let res = yield CallApi2("get", url, null);
		if (res.data != null && !res.data.isError) {

			var itemCart = {
				"id": Id,
				"price": res.data.data.Price,
				"qty": "1",
				"sku": SKU,
				"title": res.data.data.Title,
				"productId": Id,
				"isSelected": true,
				"productName": ProductName
			}
			yield put(onAddCart(itemCart));
			showMessage('Thông báo', `Đã thêm sản phẩm ${ProductName} vào giỏ hàng`, 1);
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* onGetProductByBrandSaga(action) {
	let url = `/api/product/list-data-by-brand`;
	yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
	try {
		let res = yield CallApi2("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			yield put(fetchPaging(res.data));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}

}
export function* onGetProductListSaga(action) {
	let url = `/api/product/list-data-client`;
	yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
	try {
		let res = yield CallApi2("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			yield put(fetchPagingProduct(res.data));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}

}

export function* onGetListBestSellerProductSaga(action) {
	let url = `/api/product/list-data-best-seller`;
	yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
	try {
		let res = yield CallApi2("get", url, null);
		if (res.data != null && !res.data.isError) {
			yield put(updateArray({ fieldName: "listBestSeller", fieldValue: res.data.data }));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}

}
export default function* lhtSaga() {
	yield takeEvery(onGetList.toString(), getListSaga);
	yield takeEvery(onGetItem.toString(), getItemSaga);
	yield takeEvery(onAddCartHome.toString(), getVariantDefaultSaga);
	yield takeEvery(onGetProductByBrand.toString(), onGetProductByBrandSaga);
	yield takeEvery(onGetListProduct.toString(), onGetProductListSaga);
	yield takeEvery(onGetListBestSellerProduct.toString(), onGetListBestSellerProductSaga);

}
