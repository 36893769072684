import { all } from "redux-saga/effects";
import LHTSaga from "./sage-base";
import lhtCodeSaga from "./saga-code";
import newSaga from "./../area/admin/admin-news/saga"
import newGroupSaga from "./../area/admin/admin-news-group/saga"
import brandSaga from "./../area/admin/admin-brand/saga"
import adminContactSage from "./../area/admin/admin-contact/saga"
import adminCategory from "./../area/admin/moduleProducts/admin-category/saga"
import adminManufacture from "./../area/admin/moduleProducts/admin-manufacture/saga";
import adminProduct from "./../area/admin/moduleProducts/admin-product/saga";
// import adminCrawl from "./../area/admin/admin-crawl-data/sagas";
import autoGenSaga from "./../area/admin/admin-auto-gen/saga";
import orderSaga from "./../area/admin/moduleProducts/admin-order/saga";
import clientProduct from "./../area/client/products/saga";
import clientLeftMenu from "./../area/client/menu-bar/saga";
import clientHomeSage from "./../area/client/home/saga";
import clientContactSage from "./../area/client/contact/saga";
import clientNewsSage from "./../area/client/news/saga";
import clientBrandSaga from "./../area/client/brand/saga";
import clientPaymentSaga from "./../area/client/payment/saga";

export function* rootSaga() {
    yield all([
        LHTSaga(),
        lhtCodeSaga(),
        newSaga(),
        newGroupSaga(),
        brandSaga(),
        autoGenSaga(),
        adminCategory(),
        adminManufacture(),
        adminProduct(),
        // adminCrawl(),
        adminContactSage(),
        orderSaga(),
        clientProduct(),
        clientLeftMenu(),
        clientHomeSage(),
        clientContactSage(),
        clientNewsSage(),
        clientPaymentSaga(),
        clientBrandSaga()
    ])
}
