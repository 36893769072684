
import axios from 'axios';
export const API_DOMAIN = "";//"https://localhost:44314"
export const TenantID = 10;
export async function getToken() {
    // get the existing token from localstorage
    try {
        var tk = localStorage.getItem("accessToken")
        if (!tk) {
            // history.push("/login");
            window.location.href = "/login";
        }
        const jwtPayload = JSON.parse(window.atob(tk.split('.')[1]))
        // create a timestamp for now down to the second
        const now = Math.floor(Date.now() / 1000);
        // Check if the expires timestamp is less than now - meaning it's in the past and has expired
        if (now > jwtPayload.exp) {
            var url = API_DOMAIN + '/api/admin/refresh-token'
            const res = await axios.post(url, {
                "accessToken": tk,
                "refreshToken": localStorage.getItem("refreshToken")
            });
            if (res.data.isError) {
                localStorage.clear();

                // history.push("/login");
                // return undefined;
                window.location.href = "/login";
            }
            else {
                localStorage.setItem('accessToken', res.data.data.tokenKey);
                localStorage.setItem('refreshToken', res.data.data.RefreshToken);
                //return res.data.data.tokenKey;
                return {
                    Authorization: `bearer ${res.data.data.tokenKey}`
                }
            }
        }
        else {
            return {
                Authorization: `bearer ${tk}`
            }
        }


    } catch (exx) {
        console.log("err tk", exx)
        // return undefined;
        window.location.href = "/login";
    }

};
// export async function CallApi(method, url, request, is_not_auth) {
//     if (is_not_auth) {
//         if (method.toLowerCase() == "post") {
//             return await axios.post(url, request);
//         }
//         return await axios.get(url);
//     }
//     else {
//         var tk = await getToken();
//         if (method.toLowerCase() == "post") {
//             return await axios.post(url, request, { headers: tk });
//         }
//         return await axios.get(url, { headers: tk });
//     }

// }
export async function CallApi(method, url, request, isNotAuth) {
    var config = {
        isNotAuth: !isNotAuth ? false : isNotAuth
    }

    if (method.toLowerCase() == "post") {
        return await instance.post(url, request, { config });
    }
    return await instance.get(url, { config });

}
export async function CallApi2(method, url, request) {
    var config = {
        headers: {
            tenant_id: TenantID
        },
        config: {
            isNotAuth: true
        }
    }
    if (method.toLowerCase() == "post") {
        return await instance.post(url, request, config);
    }
    return await instance.get(url, { config });

}
const instance = axios.create({
    baseURL: API_DOMAIN,
});

// Add a request interceptor
instance.interceptors.request.use(async function (config) {
    if (!config || config.config.isNotAuth) {
        return config;
    }
    if (config.config.headers) {
        config.headers = config.config.headers;
        return config;
    }
    var headers = await getToken()
    config.headers = headers;
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});
export default instance;