import { call, put, takeEvery } from "redux-saga/effects";
import { CallApi, CallApi2 } from "../../../../commons/apiHelper"
import { updateArray, onGetItem } from '../reducer'
import { showMessage } from "../../../../commons";
import * as c from "./../../../../constants/constant";
export function* getListSaga(action) {
	let url = c.API_DOMAIN + `/api/category/get-dropdown-client`;
	try {
		let res = yield CallApi2("get", url, null);
		if (res.data != null && !res.data.isError) {
			yield put(updateArray({ fieldName: "listData", fieldValue: res.data.data }));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
	}
}
export default function* lhtSaga() {
	yield takeEvery(onGetItem.toString(), getListSaga);
}