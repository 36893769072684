import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    listData: [],
    listCate: [],
    listManufacture: [],
    countryList: [],
    filter: {
        keyword: "",
        pageIndex: 1,
        pageSize: 15, // Default value
        totalPage: 1,
        totalRecord: 0,
        status: -1,
        category: -1,
        type: -1,
        sortPrice: 1,
        sortAz: 1
    },
    isCheckAll: false,
    arrCheck: [],
    item: {}, // item table
    imgs: [],
    fileList: [],
    id: 0,
    isLoading: false,
    isShowFrom: 0,
    CatalogProperties: [],
    dataPropertiesChecked: [],
    expandedKeys: [],
    checkedKeys: [],
    listOption: [
        { value: 1, label: 'Size', key: 'size', values: [] },
        { value: 2, label: 'Màu sắc', key: 'mau-sac', values: [] },
        { value: 3, label: 'Chất liệu', key: 'chat-lieu', values: [] },
    ],
    attributes: [],
    variants: []
});

export const productSlice = createSlice({
    name: 'productSlice',
    initialState: initialState,
    reducers: {
        fetchPaging: function (state, action) {
            const data = action.payload;
            return state.set('listData', Immutable.fromJS(data.data))
                .updateIn(['filter', 'totalRecord'], () => data.totalItems);
        },
        updateFieldSingle: function (state, action) {
            return state.set(action.payload.fieldName, action.payload.fieldValue);
        },
        updateFieldInModel: (state, action) => {
            return state.updateIn([action.payload.nameModel, action.payload.fieldName], () => action.payload.fieldValue);
        },
        updateArray: function (state, action) {
            return state.set(action.payload.fieldName, Immutable.fromJS(action.payload.fieldValue));
        },
        // side effect
        onGetList: (state) => {
            return state.set('listData', []).set("isLoading", true)
                .updateIn(['filter', 'totalRecord'], () => 0);
        },
        onGetItem: (state) => { return state },
        onSaveChange: (state) => { return state },
        onGetByCategory: (state) => { return state },
        onSetDefaultTree: function (state, action) {
            let { expandedKeys, checkedKeys } = action.payload;
            return state.set("expandedKeys", expandedKeys).set("checkedKeys", checkedKeys);
        }

    }
})

export const { fetchPaging, updateFieldSingle, updateFieldInModel, updateArray, onGetList, onGetItem, onSaveChange, onGetByCategory } = productSlice.actions
export default productSlice.reducer;