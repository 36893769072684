import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import { menuLists } from './../constants/constant';
export default function SidebarMenu(props) {
    var m = window.location.pathname;
    let [menus, SetMenu] = useState(menuLists)
    useEffect(() => {

        menus.forEach(element => {
            var ft = element.child.filter(x => x.path == m);
            if (ft && ft.length > 0) {
                element.isOpen = true
            }

        });
        SetMenu([...menus])
    }, [m]);
    const onLogout = () => {
        localStorage.clear();
        window.location.href = "/login";
    }
    const onShowChild = (params) => {
        menus.forEach(element => {
            element.isOpen = false;
            if (element.id == params.id) {
                element.isOpen = !element.isOpen
            }

        });
        SetMenu([...menus])

    }
    console.log("menus", menus)
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a className="brand-link">
                <span className="brand-text font-weight-light" style={{ textTransform: "uppercase" }}>Web quản trị v0.1</span>
            </a>
            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img src="/img/avatar.png" className="img-circle elevation-2" />
                    </div>
                    <div className="info">
                        <a className="d-block">xin chào! {props.userName || 'NgoaLong'}</a>
                        {
                            props.userName ?
                                <a onClick={() => onLogout()} style={{ color: "#FFF", fontWeight: 500, textTransform: "uppercase" }} >Đăng xuất</a>
                                :
                                null
                        }

                    </div>
                </div>

                <nav className="mt-2">

                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                        {
                            menus.map((item, index) =>
                                <li className={item.isOpen ? "nav-item menu-open" : "nav-item"} key={index} onClick={() => onShowChild(item)}>
                                    <a className="nav-link">
                                        <i className={item.iconClass}></i><p> {item.title} <i className="right fas fa-angle-left"></i> </p>
                                    </a>
                                    {
                                        item.child && item.child.length > 0 ?
                                            <ul className="nav nav-treeview">
                                                {
                                                    item.child.map((ch, j) =>
                                                        <li className="nav-item" key={j}>
                                                            <NavLink exact={ch.exact} className="nav-link" activeClassName="active" to={ch.path}><span className="fa fa-angle-right" aria-hidden="true"></span> {ch.title}</NavLink>
                                                        </li>

                                                    )
                                                }

                                            </ul>
                                            :
                                            null
                                    }

                                </li>
                            )
                        }
                    </ul>
                </nav>
            </div>

        </aside>
    )
}
