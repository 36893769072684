import React from 'react';
import {   notification } from 'antd';
import {
    NotificationOutlined
} from '@ant-design/icons';
export function showMessage(message, des, type) {
    switch (type) {
        case 1: // sucess
            notification.success({
                message: message,
                description: des,
                placement: "bottomRight",
                duration: 5,
                className: "ngoalong",
                rtl: true,
                style: { "background": "green", "color": "#fff" },
                icon: <NotificationOutlined style={{ color: "#fff" }} />
            });
            break;
        case 2:
            notification.error({
                message: message,
                description: des,
                placement: "bottomRight",
                duration: 5,
                className: "ngoalong",
                style: { "background": "red", "color": "#fff" },
                rtl: true,
                icon: <NotificationOutlined style={{ color: "#fff" }} />
            });
            break;
        default:
            break;
    }

}