import { call, put, takeEvery } from "redux-saga/effects";
import * as c from "./../../../../constants/constant";
import { CallApi2 } from "./../../../../commons/apiHelper"
import { fetchPaging, updateFieldSingle, updateArray, onGetList, onGetItem, onSaveChange, onGetProductByCate } from './../reducer'
import { showMessage } from "./../../../../commons";
export function* getItemSaga(action) {
	try {
		let { id, sku } = action.payload;

		let url = `/api/product/client-get-product/${id}/${sku}`;
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
		let res = yield CallApi2("get", url, true);
		if (res.data != null && !res.data.isError) {
			yield put(updateFieldSingle({ fieldName: "isShowFrom", fieldValue: 1 }));
			yield put(updateArray({ fieldName: "item", fieldValue: res.data.data }));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}

export function* onGetProductByCateSaga(action) {
	try {
		let url = `/api/product/list-data-by-category`;
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
		let res = yield CallApi2("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			yield put(updateArray({ fieldName: "listProductByCate", fieldValue: res.data.data }));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* saveItemSaga(action) {
	let { req, filter, url } = action.payload;
	try {
		let res = yield call(CallApi2("post", url, req));
		if (res.data && !res.data.isError) {
			yield put(updateFieldSingle({ fieldName: "isShowFrom", fieldValue: 0 }))
			if (filter) {
				yield put(onGetList(filter));
				yield put(updateArray({ fieldName: 'item', fieldValue: {} }));

			}
			showMessage('Success!', 'Action success', 1);
		}
		else {
			showMessage('Error!!', res.data.message, 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export default function* lhtSaga() {
	yield takeEvery(onGetItem.toString(), getItemSaga);
	yield takeEvery(onSaveChange.toString(), saveItemSaga);
	yield takeEvery(onGetProductByCate.toString(), onGetProductByCateSaga);

}
