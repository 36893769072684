import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    listData: [
       
    ],
    tableSetting:
    {
        "table_name":"tbl_test",
        "router_name":"api/test",
        "store_get_all":"store_get_all",
        "entity_model":"entity_model",
        "store_add_item":"store_add_item"
    }
});

export const autoGenSlice = createSlice({
    name: 'autoGenSlice',
    initialState: initialState,
    reducers: {
        fetchPaging: function (state, action) {
            const data = action.payload;
            return state.set('listData', Immutable.fromJS(data.TrackingEntities))
                .updateIn(['filter', 'TotalRecord'], () => data.TotalRecord);
        },
        updateFieldSingle: function (state, action) {
            return state.set(action.payload.fieldName, action.payload.value);
        },
        updateFieldInModel: (state, action) => {
            return state.updateIn([action.payload.nameModel, action.payload.fieldName], () => action.payload.fieldValue);
        },
        updateArray: function (state, action) {
            return state.set(action.payload.fieldName, Immutable.fromJS(action.payload.fieldValue));
        },
         // side effect
        onGetList: (state) => { 
            return state.set('listData', []).set("isLoading", true)
                .updateIn(['filter', 'totalRecord'], () => 0);
        },
        onGetItem: (state) => { return state },
        onSaveChange: (state) => { return state },

    }
})

export const { fetchPaging, updateFieldSingle, updateFieldInModel, updateArray,onGetList,onGetItem,onSaveChange } = autoGenSlice.actions
export default autoGenSlice.reducer;