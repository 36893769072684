import { call, put, takeEvery } from "redux-saga/effects";
import * as c from "./../../../../../constants/constant";
import { CallApi } from "./../../../../../commons/apiHelper"
import { fetchPaging, onGetPropertiesValue, updateFieldSingle, updateArray, onGetList, onGetItem, onSaveChange, fetchFacturePaging, onGetManufacture, updateFieldInModel, onChangeManufacture, onGetProperties, fetchPropertiesPaging, onDelProperties, onAddProperties } from './../reducer'
import { showMessage } from "./../../../../../commons";
export function* getListSaga(action) {
	let url = c.API_DOMAIN + `/api/category/get-dropdown`;
	yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
	try {
		let res = yield CallApi("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			//yield put(fetchPaging(res.data));
			yield put(updateArray({ fieldName: "listData", fieldValue: res.data.data }));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* getItemSaga(action) {
	try {
		let url = c.API_DOMAIN + `/api/category/get-item/${action.payload}`;
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
		let res = yield CallApi("get", url);
		if (res.data != null && !res.data.isError) {
			yield put(updateFieldSingle({ fieldName: "isShowFrom", fieldValue: 1 }));
			yield put(updateArray({ fieldName: "item", fieldValue: res.data.data }));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* getManufactureSaga(action) {
	try {
		let url = c.API_DOMAIN + `/api/category/get-manufacture`;
		let res = yield CallApi("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			yield put(updateFieldInModel({ nameModel: "filterManufacture", fieldName: "isShowList", fieldValue: 1 }));
			yield put(fetchFacturePaging(res.data));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* saveItemSaga(action) {
	let { req, filter, url } = action.payload;
	try {
		let res = yield CallApi("post", url, req);
		if (res.data && !res.data.isError) {
			yield put(updateFieldSingle({ fieldName: "isShowFrom", fieldValue: 0 }))
			if (filter) {
				yield put(onGetList(filter));
				yield put(updateArray({ fieldName: 'item', fieldValue: {} }));

			}
			showMessage('Success!', 'Action success', 1);
		}
		else {
			showMessage('Error!!', res.data.message, 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* changeManufactureSaga(action) {
	let url = c.API_DOMAIN + `/api/category/change-manufacture`;
	yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
	try {
		let res = yield CallApi("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* getPropertySaga(action) {
	let url = c.API_DOMAIN + `/api/category/get-property`;
	//yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: true }));
	try {
		let res = yield CallApi("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			yield put(fetchPropertiesPaging(res.data));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		//yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* delPropertySaga(action) {
	let url = c.API_DOMAIN + `/api/category/delete-properties`;
	try {
		let res = yield CallApi("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			yield put(onGetProperties(action.payload.filter));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		// yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* onAddPropertiesSaga(action) {
	let url = c.API_DOMAIN + `/api/category/add-properties`;
	try {
		let res = yield CallApi("post", url, action.payload);
		if (res.data != null && !res.data.isError) {
			yield put(updateArray({ fieldName: "propertiesValuesNews", fieldValue: [] }))
			yield put(updateArray({ fieldName: "propertiesItem", fieldValue: { name: "", type: 1, catalogId: 0 } }))
			yield put(onGetProperties(action.payload.filter));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		// yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export function* onGetPropertiesValueSaga(action) {
	try {
		let url = c.API_DOMAIN + `/api/category/get-properties-value/${action.payload}`;
		let res = yield CallApi("get", url);
		if (res.data != null && !res.data.isError) {
			yield put(updateArray({ fieldName: 'propertiesValues', fieldValue: res.data.data.propertiesValue }));
			yield put(updateArray({ fieldName: 'propertiesItem', fieldValue: res.data.data.property }));
			yield put(updateArray({ fieldName: 'isDetailProperties', fieldValue: 1 }));
		}
		else {
			showMessage('Error!!', 'There is error in too processors', 2);
		}

	} catch (err) {
		console.log("Ex", err)
		showMessage("Error!", err, 2)
	}
	finally {

	}
}

export default function* lhtSaga() {
	yield takeEvery(onGetList.toString(), getListSaga);
	yield takeEvery(onGetItem.toString(), getItemSaga);
	yield takeEvery(onSaveChange.toString(), saveItemSaga);
	yield takeEvery(onGetManufacture.toString(), getManufactureSaga);
	yield takeEvery(onChangeManufacture.toString(), changeManufactureSaga);
	yield takeEvery(onGetProperties.toString(), getPropertySaga);
	yield takeEvery(onDelProperties.toString(), delPropertySaga);
	yield takeEvery(onAddProperties.toString(), onAddPropertiesSaga);
	yield takeEvery(onGetPropertiesValue.toString(), onGetPropertiesValueSaga);

}
