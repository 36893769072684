import { call, put, takeEvery } from "redux-saga/effects";
import { CallApi2, API_DOMAIN } from "../../../../commons/apiHelper"
import { updateFieldSingle, updateArray, onSaveChange } from '../reducer'
import { showMessage } from "../../../../commons";

export function* saveItemSaga(action) {
	var url = API_DOMAIN + "/api/support/add-news-contact";
	try {
		let res = yield CallApi2("post", url, action.payload);
		if (res.data && !res.data.isError) {
			yield put(updateArray({
				fieldName: "item", fieldValue: {
					"fromName": "",
					"email": "",
					"phone": "",
					"note": "",
					"type": 1
				}
			}))
			showMessage('Thông báo', "Đã gửi thành công", 1);
		}
		else {
			showMessage('Error!!', res.data.message, 2);
		}
	} catch (err) {
		console.log("Ex", err)
		showMessage('Error sys!', err, 2);
	}
	finally {
		yield put(updateFieldSingle({ fieldName: "isLoading", fieldValue: false }));
	}
}
export default function* lhtSaga() {
	yield takeEvery(onSaveChange.toString(), saveItemSaga);
}
