import { createSlice } from '@reduxjs/toolkit';
import Immutable from 'immutable';

const initialState = Immutable.fromJS({
    codeGroup: [],
    codeValue: [],
    itemCode: {
        codeId: '',
        codeValue: '',
        codeValueDes: '',
        parentCodeValue: ''
    },
    filter: {
        keyword: "",
        pageIndex: 1,
        pageSize: 20, // Default value
        totalPage: 1,
        totalRecord: 60,
        status: 255,
        category: -1

    },
    isShowFromAddCode: 0

});

export const codeSlice = createSlice({
    name: 'codeSlice',
    initialState: initialState,
    reducers: {
        fillCodeValue: (state, action) => {
            const data = action.payload;
            return state.set('codeValue', Immutable.fromJS(data.data))
                .updateIn(['filter', 'totalRecord'], () => data.totalItems);
        },
        updateFieldSingleCode: (state, action) => {
            return state.set(action.payload.fieldName, action.payload.fieldValue);
        },
        updateFieldInModelCode: (state, action) => {
            return state.updateIn([action.payload.nameModel, action.payload.fieldName], () => action.payload.fieldValue);
        },
        updateArrayCode: (state, action) => {
            return state.set(action.payload.fieldName, Immutable.fromJS(action.payload.fieldValue));
        },
        // side effect
        getAllCodeValue: (state) => {
            return state.set('codeSlice', []).set("isLoading", true)
                .updateIn(['filter', 'totalRecord'], () => 0);
        },
        getAllCodeGroup: (state )=> {return state},
        deleteCodeValue: (state) => { return state },
        addItemCodeValue: (state) => { return state }
       

    }
})

export const { fillCodeValue ,updateFieldSingleCode,updateFieldInModelCode,updateArrayCode,getAllCodeValue,addItemCodeValue,getAllCodeGroup,deleteCodeValue} = codeSlice.actions
export default codeSlice.reducer;