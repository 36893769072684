import React, { Component } from "react";
import { Route } from "react-router-dom";
import LayoutIndex from '../layout/client/Layout-index';
function RouterLayoutClient({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={matchProps => (
                <LayoutIndex> <Component {...matchProps} /></LayoutIndex>

            )}
        />
    )
}

export default RouterLayoutClient